export function isImgElement(value: unknown): value is HTMLImageElement {
  return value instanceof HTMLImageElement;
}

export function isHTMLElement(value: unknown): value is HTMLElement {
  return value instanceof HTMLElement;
}

export function isHTMLInputElement(value: unknown): value is HTMLInputElement {
  return value instanceof HTMLInputElement;
}

export function isHTMLTextareaElement(value: unknown): value is HTMLTextAreaElement {
  return value instanceof HTMLTextAreaElement;
}

export function isNode(value: unknown): value is Node {
  return value instanceof Node;
}

export function isElement(value: unknown): value is Element {
  return value instanceof Element;
}

export function moveToElement(selector: string) {
  window?.document.querySelector(selector)?.scrollIntoView({
    behavior: 'smooth',
  });
}
